<template>
  <v-app>   

    <v-main>
      <!-- <v-container class="fill-height container-background" fluid></v-container> -->
      <v-container 
            class="fill-height justify-center container-background" 
            fluid
        >
           <v-row 
            class="text-center"
            style="z-index:10"
          >
              <v-col cols="12">
                <v-img
                  :src="require('./assets/logo.png')"
                  class="my-3"
                  contain
                  height="200"
                />
              </v-col>

              <v-col cols="12" class="mb-4">
                <h1 class="primary--text display-2 font-weight-bold mb-3">
                  Welcome to {{ schoolName }}
                </h1>

                <p class="display-1 font-weight-light primary--text">
                  Student and Staff Online Portal
                </p>  
              </v-col>

              

              <v-col
                cols="12"
                md="2"
                offset-md="3"
                
              >
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card
                        class="mx-auto d-flex align-center"
                        width="300"
                        height="200"
                        color="primary"
                        dark
                        href="https://ereport-tss.ttschoolnet2.com"    
                      >
                        <v-card-text>
                          <h2 class="text-h6 white--text text-center">E-Reports</h2>
                        </v-card-text>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="#036358"
                          ></v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </template>
                  </v-hover>
              </v-col>  
              <v-col
                cols="12"
                md="2"
              >
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card
                        class="mx-auto d-flex align-center"
                        width="300"
                        height="200"
                        color="primary"
                        dark
                        href="https://app-tss.ttschoolnet2.com"    
                      >
                        <v-card-text>
                          <h2 class="text-h6 white--text text-center">Staff Portal</h2>
                        </v-card-text>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="#036358"
                          ></v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </template>
                  </v-hover>
                  
              </v-col>  
              <v-col
                cols="12"
                md="2"
              >
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card
                        class="mx-auto d-flex align-center"
                        width="300"
                        height="200"
                        color="primary"
                        dark
                        href="https://student-registration-tss.ttschoolnet2.com"    
                      >
                        <v-card-text>
                          <h2 class="text-h6 white--text text-center">Student Registration</h2>
                        </v-card-text>

                        <v-fade-transition>
                          <v-overlay
                            v-if="hover"
                            absolute
                            color="#036358"
                          ></v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </template>
                  </v-hover>
              </v-col>  
           </v-row>
        </v-container>
      
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
    
  },

  data: () => ({
    schoolName: "Tunapuna Secondary School",
  }),
};
</script>

<style scoped>
    .container-background{
        /* background-image: url('./assets/background.jpeg');  */
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: contain;
        /* filter: brightness(90%); */
        position: absolute;
    }
       
</style> 
